import React, { useState, useEffect, useContext } from 'react'
import {
  Button,
  Text,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import axios from 'utils/axios'

import DispatchContext from 'context/DispatchContext'

let timer

const AdminForm = ({ user, hideAdminForm }) => {
  const appDispatch = useContext(DispatchContext)
  const [adminCode, setAdminCode] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const toastIdRef = React.useRef()

  useEffect(() => {
    timer = setTimeout(hideAdminForm, 60000)
    return () => clearTimeout(timer)
  }, [user, hideAdminForm])

  async function handleSubmit(e) {
    e.preventDefault()

    if (!adminCode) {
      setIsValid(false)
      return
    }

    setIsLoading(true)
    closeToast()

    try {
      const response = await axios.post('/auth/signin-admin', {
        adminCode,
        userId: user.userId,
      })
      const { token } = response.data
      appDispatch({ type: 'login', data: { user, token } })
    } catch (err) {
      let errorMessage
      if (err.response) {
        if (err.response.data.error === 'Please authenticate.') {
          hideAdminForm()
        }
        errorMessage = err.response.data.error
      } else {
        errorMessage = err.message
      }
      toastIdRef.current = toast({
        title: 'An error occurred.',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    setIsLoading(false)
  }

  function closeToast() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Text textAlign="center" fontWeight="semibold">
        Welcome back{' '}
        <span style={{ textTransform: 'capitalize' }}>{user.firstName}</span>!
      </Text>
      <Text textAlign="center" color="gray.500" mb={8}>
        Enter the admin code to continue.
      </Text>
      <FormControl isInvalid={!isValid} mb={4}>
        <FormLabel htmlFor="password">Admin Code</FormLabel>
        <Input
          type="password"
          id="password"
          name="password"
          size="lg"
          value={adminCode}
          onChange={(e) => setAdminCode(e.target.value)}
          onBlur={(e) => setIsValid(!!e.target.value)}
          autoComplete="new-password"
        />
        <FormErrorMessage>admin code is required!</FormErrorMessage>
      </FormControl>

      <Button
        width="100%"
        colorScheme="blue"
        type="submit"
        size="lg"
        isLoading={isLoading}
      >
        Sign in
      </Button>
    </form>
  )
}

export default AdminForm
