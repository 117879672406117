import React, { useState } from 'react'
import { Badge, Box, Image, Text, useColorModeValue } from '@chakra-ui/react'

import Page from 'components/Page'
import LoginForm from './components/LoginForm'
import AdminForm from './components/AdminForm'

const Login = () => {
  const [showAdminForm, setShowAdminForm] = useState(false)
  const [user, setUser] = useState({})
  const bg = useColorModeValue('white', 'gray.800')

  function handleLoginSucces(user) {
    setUser(user)
    setShowAdminForm(true)
  }

  return (
    <Page title="Login">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minH="100vh"
        borderTopWidth={6}
        borderColor="blue.500"
      >
        <Box
          p={6}
          minW="360px"
          bg={bg}
          borderRadius={4}
          boxShadow="lg"
          borderWidth={1}
          borderColor="gray.100"
        >
          <Box>
            <Image
              height="60px"
              width="200px"
              src="/logo.png"
              alt="logo"
              margin="auto"
            />
          </Box>
          <Text
            textAlign="center"
            fontWeight="semibold"
            mb={6}
            textTransform="uppercase"
          >
            <Badge colorScheme="green">Admin</Badge>
          </Text>

          {showAdminForm ? (
            <AdminForm
              user={user}
              hideAdminForm={() => setShowAdminForm(false)}
            />
          ) : (
            <LoginForm onSuccess={handleLoginSucces} />
          )}
        </Box>
      </Box>
    </Page>
  )
}

export default Login
