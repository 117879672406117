import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Badge, Box, List, ListIcon, useColorModeValue } from '@chakra-ui/react'

import navConfig from './navConfig'

const NavItem = ({ item, errorsCount }) => {
  const match = useRouteMatch(item.href)
  const bg = useColorModeValue('blue.100', 'blue.900')

  return (
    <Link to={item.href}>
      <Box
        as="li"
        fontWeight="semibold"
        color={match?.isExact ? 'blue.400' : 'gray.600'}
        bg={match?.isExact ? bg : 'initial'}
        py={3}
        px={4}
        borderLeft={'4px'}
        borderLeftColor={match?.isExact ? 'blue.400' : 'transparent'}
        _hover={{ color: 'blue.500' }}
        display="flex"
        alignItems="center"
      >
        <ListIcon as={item.icon} h={5} w={5} mr={4} />
        {item.title}
        {item.title === 'Errors' && errorsCount > 0 && (
          <Badge
            ml={1}
            px={1}
            borderRadius="50%"
            fontSize="0.8em"
            colorScheme="red"
          >
            {errorsCount}
          </Badge>
        )}
      </Box>
    </Link>
  )
}

const SideNav = ({ errorsCount }) => {
  return (
    <Box
      w={{ lg: '180px', xl: '210px' }}
      position="fixed"
      left={{ base: '-100%', lg: '0' }}
      top="65px"
      h="calc(100vh - 65px)"
    >
      <List alignItems="center" pt={8}>
        {navConfig.map((navItem) => (
          <NavItem item={navItem} errorsCount={errorsCount} />
        ))}
      </List>
    </Box>
  )
}

export default SideNav
