import { FaThLarge, FaMobileAlt, FaGraduationCap } from 'react-icons/fa'
import { ImUsers, ImWarning } from 'react-icons/im'
import { SiAmazonaws } from 'react-icons/si'
import { BsQuestionSquare } from 'react-icons/bs'
import { MdVideoCall } from 'react-icons/md'
import { FiEdit } from 'react-icons/fi'

const navConfig = [
  {
    title: 'Dashbaord',
    icon: FaThLarge,
    href: '/dashboard',
  },
  {
    title: 'Apps',
    icon: FaMobileAlt,
    href: '/apps',
  },
  {
    title: 'Questions',
    icon: BsQuestionSquare,
    href: '/questions',
  },
  {
    title: 'XML Editor',
    icon: FiEdit,
    href: '/questions/edit',
  },
  {
    title: 'Users',
    icon: ImUsers,
    href: '/users',
  },
  {
    title: 'Exams',
    icon: FaGraduationCap,
    href: '/exams',
  },
  {
    title: 'Webinars',
    icon: MdVideoCall,
    href: '/webinars',
  },
  {
    title: 'Polly',
    icon: SiAmazonaws,
    href: '/polly',
  },
  {
    title: 'Errors',
    icon: ImWarning,
    href: '/errors',
  },
]

export default navConfig
