import React from 'react'
import { Progress } from '@chakra-ui/react'

const LoadingScreen = () => {
  return (
    <Progress
      position="absolute"
      top="45%"
      left="calc(50% - 100px)"
      width="300px"
      size="xs"
      colorScheme="blue"
      isIndeterminate
    />
  )
}

export default LoadingScreen
