import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  Box,
  Divider,
  Image,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
} from '@chakra-ui/react'

import navConfig from './navConfig'

const NavItem = ({ item, onClick }) => {
  const match = useRouteMatch(item.href)

  return (
    <Link to={item.href}>
      <Box
        display="flex"
        alignItems="center"
        pl={4}
        border="1px"
        borderColor="rgba(155, 155, 155, 0.15)"
        py={4}
        mt={2}
        borderRadius="md"
        bg={match?.isExact ? 'blue.500' : 'transparent'}
        color={match?.isExact ? 'white' : 'gray.600'}
        _hover={{ bg: match?.isExact ? 'blue.500' : 'blue.100' }}
        onClick={onClick}
      >
        <Box as={item.icon} mr={4} />
        <Text textAlign="center" fontSize="lg" fontWeight="semibold">
          {item.title}
        </Text>
      </Box>
    </Link>
  )
}

const DrawerNav = ({ isOpen, close }) => {
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={close}>
      <DrawerOverlay />
      <DrawerContent zIndex={9999} overflow="auto">
        <DrawerCloseButton mt={2} />
        <DrawerHeader py={2}>
          <Image height="48px" src="/logo.png" alt="logo" />
        </DrawerHeader>

        <Divider />

        <DrawerBody>
          {navConfig.map((navItem, i) => (
            <NavItem key={i} item={navItem} onClick={close} />
          ))}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
export default DrawerNav
