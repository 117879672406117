import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import LoginPage from './pages/Login'
import DashboardLayout from './layouts/DashboardLayout'
import AuthRoute from './components/AuthRoute'
import GuestRoute from './components/GuestRoute'
import LoadingScreen from './components/LoadingScreen'

function Routes() {
  return (
    <Switch>
      <GuestRoute path="/" component={LoginPage} exact />
      <AuthRoute
        path="/"
        render={(props) => (
          <DashboardLayout {...props}>
            <Suspense fallback={<LoadingScreen />}>
              <Switch>
                <Route
                  exact
                  path="/dashboard"
                  component={lazy(() => import('./pages/Dashboard'))}
                />
                <Route
                  exact
                  path="/apps"
                  component={lazy(() => import('./pages/Apps'))}
                />
                <Route
                  exact
                  path="/questions"
                  component={lazy(() => import('./pages/Questions'))}
                />
                <Route
                  exact
                  path="/questions/edit"
                  component={lazy(() => import('./pages/QuestionEditor'))}
                />
                <Route
                  exact
                  path="/users"
                  component={lazy(() => import('./pages/Users'))}
                />
                <Route
                  exact
                  path="/exams"
                  component={lazy(() => import('./pages/Exams'))}
                />
                <Route
                  exact
                  path="/webinars"
                  component={lazy(() => import('./pages/Webinars'))}
                />
                <Route
                  exact
                  path="/polly"
                  component={lazy(() => import('./pages/Polly'))}
                />
                <Route
                  exact
                  path="/errors"
                  component={lazy(() => import('./pages/Errors'))}
                />
                <Redirect to="/dashboard" />
              </Switch>
            </Suspense>
          </DashboardLayout>
        )}
      />
    </Switch>
  )
}

export default Routes
