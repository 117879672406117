import React, { useContext } from 'react'
import {
  Box,
  Flex,
  Image,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { FiPower, FiMenu, FiSun, FiMoon } from 'react-icons/fi'

import DispatchContext from 'context/DispatchContext'
import StateContext from 'context/StateContext'

const TopBar = ({ openNav }) => {
  const appDispatch = useContext(DispatchContext)
  const appState = useContext(StateContext)
  const { colorMode, toggleColorMode } = useColorMode()

  const bg = useColorModeValue('white', 'gray.800')

  return (
    <Box position="fixed" w="100%" zIndex={11}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
        borderBottom="1px"
        borderColor="gray.200"
        bg={bg}
      >
        <Box d="flex" alignItems="center" flex={{ base: 1, lg: 'initial' }}>
          <IconButton
            icon={<FiMenu />}
            variant="outline"
            mr={4}
            display={{ lg: 'none' }}
            p={3}
            onClick={() => openNav()}
          />
          <Box width="100%">
            <Image
              height="48px"
              src="/logo.png"
              alt="logo"
              my={1}
              marginX="auto"
            />
          </Box>
        </Box>

        <Flex alignItems="center">
          <IconButton
            icon={colorMode === 'light' ? <FiMoon /> : <FiSun />}
            onClick={toggleColorMode}
            mr={4}
          />

          <Menu>
            <MenuButton>
              <Avatar
                size="md"
                name={appState.user.username}
                src={appState.user.avatar}
                bg="green.200"
                p="2px"
              />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => appDispatch({ type: 'logout' })}>
                <Box as={FiPower} mr={4} />
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    </Box>
  )
}

export default TopBar
