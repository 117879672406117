import React, { Component, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import StateContext from 'context/StateContext'

const GuestRoute = ({ component: Component }) => {
  const appState = useContext(StateContext)

  return (
    <Route
      render={(props) =>
        !appState.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location },
            }}
          />
        )
      }
    ></Route>
  )
}

export default GuestRoute
