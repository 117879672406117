import React, { useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { Box, Text, useColorModeValue } from '@chakra-ui/react'

import axios from '../../utils/axios'
import TopBar from './TopBar'
import SideNav from './SideNav'
import DrawerNav from './DrawerNav'

const DashboardLayout = ({ children }) => {
  const { data: errors } = useQuery('errors', async () => {
    const response = await axios.get('/errors')
    return response.data
  })

  const { data: onlineCount } = useQuery('online-count', async () => {
    const response = await axios.get('/users/online-count')
    return response.data
  })

  const [isNavOpen, setIsNavOpen] = useState(false)
  const openNav = () => setIsNavOpen(true)
  const closeNav = () => setIsNavOpen(false)

  const bg = useColorModeValue('gray.100', 'gray.700')

  const errorCount = (errors) =>
    errors.filter((err) => err.code < 5000 && err.code >= 6000).length

  return (
    <Box>
      <DrawerNav isOpen={isNavOpen} close={closeNav} />
      <TopBar openNav={openNav} />
      <SideNav errorsCount={errors ? errorCount(errors) : 0} />
      <Box
        flex={1}
        px={{ base: 4, xl: 8 }}
        paddingTop="98px"
        bg={bg}
        ml={{ base: '0', lg: '180px', xl: '210px' }}
        pb={8}
        minH="100vh"
      >
        {children}
      </Box>
      <Box
        position="fixed"
        bottom={6}
        left={6}
        display={{ base: 'none', lg: 'initial' }}
      >
        <Text fontWeight="semibold">
          Users Online: {onlineCount ? onlineCount : 0}
        </Text>
      </Box>
    </Box>
  )
}

export default DashboardLayout
