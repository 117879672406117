import React, { useEffect } from 'react'
import { useImmerReducer } from 'use-immer'
import jwtDecode from 'jwt-decode'
import axios from 'utils/axios'

import StateContext from './context/StateContext'
import DispatchContext from './context/DispatchContext'
import Routes from './Routes'

import './Table.css'

let logoutTimer

function isLoggedIn() {
  const token = localStorage.getItem('accessToken')
  const user = JSON.parse(localStorage.getItem('user'))

  if (!token || !user) {
    return false
  }

  try {
    const decoded = jwtDecode(token)

    if (Date.now() > decoded.exp * 1000) {
      return false
    }

    axios.defaults.headers.common = { Authorization: `Bearer ${token}` }

    return true
  } catch (err) {
    return false
  }
}

const App = () => {
  const initialState = {
    loggedIn: isLoggedIn(),
    token: localStorage.getItem('accessToken'),
    user: JSON.parse(localStorage.getItem('user')),
    isProfileOpen: false,
    userProfile: undefined,
  }

  // reduce should only work with state
  // working with local storage? use useEffect
  function reducer(draft, action) {
    switch (action.type) {
      case 'login':
        draft.loggedIn = true
        draft.user = action.data.user
        draft.token = action.data.token
        return
      case 'logout':
        draft.loggedIn = false
        draft.user = null
        draft.token = null
        return
      default:
        return
    }
  }

  const [state, dispatch] = useImmerReducer(reducer, initialState)

  useEffect(() => {
    if (state.loggedIn) {
      localStorage.setItem('user', JSON.stringify(state.user))
      localStorage.setItem('accessToken', state.token)
      // set up auto logout
      const decoded = jwtDecode(state.token)
      const remainingSeconds = decoded.exp - decoded.iat
      logoutTimer = setTimeout(
        () => dispatch({ type: 'logout' }),
        remainingSeconds * 1000,
      )
    } else {
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
      clearTimeout(logoutTimer)
    }
  }, [state.loggedIn])

  useEffect(() => {
    if (state.token) {
      axios.setAuthHeader(state.token)
      axios.setUnAuthResponseAction(() => dispatch({ type: 'logout' }))
    } else {
      axios.removeAuthHeader()
    }
  }, [state.token])

  // validate token on first render
  useEffect(() => {
    if (state.loggedIn) {
      async function checkToken() {
        try {
          const response = await axios.post('/auth/check-token', {
            token: state.token,
          })

          const { isValid } = response.data
          if (!isValid) {
            return dispatch({ type: 'logout' })
          }
        } catch (e) {
          console.log('There was a problem or the request was cancelled!')
        }
      }
      checkToken()
    }
  }, [])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <Routes />
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export default App
