import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import 'react-perfect-scrollbar/dist/css/styles.css'

import App from './App'

ReactDOM.render(
  <ChakraProvider resetCSS={true}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ChakraProvider>,
  document.getElementById('root'),
)
