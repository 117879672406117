import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'

import StateContext from 'context/StateContext'

const AdminRoute = ({ component: Component, render, ...rest }) => {
  const appState = useContext(StateContext)

  if (!appState.loggedIn) {
    return <Redirect to="/" />
  }

  return render ? render({ ...rest }) : <Component {...rest} />
}

export default AdminRoute
