import React, { useState } from 'react'
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import axios from 'utils/axios'

const LoginForm = ({ onSuccess }) => {
  const [username, setUsername] = useState({ value: '', hasError: false })
  const [password, setPassword] = useState({ value: '', hasError: false })
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const toastIdRef = React.useRef()

  async function handleSubmit(e) {
    e.preventDefault()

    if (validInput()) {
      setIsLoading(true)
      closeToast()

      try {
        const response = await axios.post('/auth/signin', {
          username: username.value,
          password: password.value,
        })
        const { user, shortLivedToken } = response.data

        axios.setAuthHeader(shortLivedToken)
        setIsLoading(false)
        onSuccess(user)
      } catch (err) {
        let errorMessage
        if (err.response) {
          errorMessage = err.response.data.error
        } else {
          errorMessage = err.message
        }
        toastIdRef.current = toast({
          title: 'An error occurred.',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        setIsLoading(false)
      }
    }
  }

  function closeToast() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  function validInput() {
    if (username.value && password.value) {
      return true
    }

    if (!username.value) {
      setUsername({ value: '', hasError: true })
    }
    if (!password.value) {
      setPassword({ value: '', hasError: true })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl mb={4} isInvalid={username.hasError}>
        <FormLabel htmlFor="username">Username</FormLabel>
        <Input
          id="username"
          name="username"
          size="lg"
          value={username.value}
          onChange={(e) =>
            setUsername({
              value: e.target.value,
              hasError: !e.target.value.length,
            })
          }
          autoComplete="username"
        />

        <FormErrorMessage>username is required</FormErrorMessage>
      </FormControl>
      <FormControl mb={4} isInvalid={password.hasError}>
        <FormLabel htmlFor="password">Password</FormLabel>
        <Input
          type="password"
          id="password"
          name="password"
          size="lg"
          value={password.value}
          onChange={(e) =>
            setPassword({
              value: e.target.value,
              hasError: !e.target.value.length,
            })
          }
          autoComplete="current-password"
        />
        <FormErrorMessage>password is required!</FormErrorMessage>
      </FormControl>

      <Button
        width="100%"
        colorScheme="blue"
        type="submit"
        size="lg"
        isLoading={isLoading}
        mb={4}
      >
        Sign in
      </Button>
    </form>
  )
}

export default LoginForm
