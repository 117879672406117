import axios from 'axios'

const instance = axios.create()

instance.setAuthHeader = function (token) {
  instance.defaults.headers.common = { Authorization: `Bearer ${token}` }
}

instance.removeAuthHeader = function () {
  instance.defaults.headers.common = null
}

instance.setUnAuthResponseAction = function (logout) {
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logout()
      }
      return Promise.reject(error)
    },
  )
}

instance.defaults.baseURL = process.env.REACT_APP_BACKENDURL

export default instance
