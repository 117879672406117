import { useEffect } from 'react'

function Page({ children, title = '' }) {
  useEffect(() => {
    document.title = `Admin | ${title}`
    window.scrollTo(0, 0)
  }, [title])

  return children
}

export default Page
